import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import Table from "../Table/Table";
import { getAllAuctions, approveAuction, rejectAuction } from "../../../_actions/user_actions";
import { Button, message, Popconfirm } from 'antd';
import moment from 'moment';

function ApproveAuctionsPage(props) {
  const dispatch = useDispatch();
  const AuctionsData = props.data.user?.auctions ?? []

  useEffect(() => {
  	dispatch(getAllAuctions())
    let intervalId = setInterval(() => {
      dispatch(getAllAuctions())
    }, 15000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 600000);

    // Cleanup interval and timeout on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [dispatch])

  const configureTable = {
    onClick: (group) => {

    },
    tableHeadings: [
      'owner', 'description', 'endDate', 'winner', 'bid', 'action', 'approver'
    ],
  }

  const ApproveComponent = (localProps) => {
    const { row } = localProps

    const removeClickHandler = (e, row) => {
      dispatch(rejectAuction({_id: row._id})).then(response => {
          if (response.payload !== null) {
            message.error(response.payload, 10)
          }
          else {
            message.success("Auction Removed")
          }
          dispatch(getAllAuctions())
      })
      e.stopPropagation()
    }

    const approveClick = () => {
    	dispatch(approveAuction({
    		auctionId: row._id,
    	})).then(response => {
	        if (response.payload !== null) {
	          message.error(response.payload, 10)
	        }
	        else {
	          message.success("Auction Approved")
	        }
          dispatch(getAllAuctions())
	    })
    }

    const actionType = () => {
      if (new Date(row.endDate) <= new Date()) {
        return (
          <Button onClick={(e => removeClickHandler(e, row))} type="primary">Resolve</Button>
        )
      }

      else if (row.approved) {
        return (
          <div>
            <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeClickHandler(e, row)} title="Are you sure you want to permanently delete this auction?" okText="Yes" cancelText="No">
              <Button onClick={(e) => e.stopPropagation()} type="danger">Reject</Button>            
            </Popconfirm>
          </div>
        )
      }

      return (
        <div>
          <Button onClick={approveClick} type="primary">Approve</Button>
          <Popconfirm onCancel={e => e.stopPropagation()} onConfirm={(e) => removeClickHandler(e, row)} title="Are you sure you want to permanently delete this auction?" okText="Yes" cancelText="No">
            <Button onClick={(e) => e.stopPropagation()} type="danger">Reject</Button>            
          </Popconfirm>
        </div>
      )
    }

    return (
      <div className='ApproveContainer' onClick={e => e.stopPropagation()}>
        {actionType()}
      </div>
    )
  }

  const updatedAuctionsData = AuctionsData.map((row, index) => {
    let newRow = {...row}
    newRow.bid = row.bid.toLocaleString('en', {useGrouping:true})
    newRow.owner = row.owner_id.firstName + " " + row.owner_id.lastName
    newRow.winner = row.winner_id.firstName + " " + row.winner_id.lastName
    newRow.endDate = moment(row.endDate).format('YYYY-MM-DD HH:mm')
    newRow.action = <ApproveComponent id={index} row={row} />
    if (row.approved) newRow.approved = row.approver
    return newRow
  })
  
  return (
    <div className='body'>
      <div className='innerBody'>
        <h1>Auctions</h1>
        <Table rowData={updatedAuctionsData} tableData={configureTable}/>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    data: state
  };
}

export default withRouter(connect(mapStateToProps)(ApproveAuctionsPage))