import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "../hoc/auth";
import AuthRedirect from "../hoc/authRedirect";
// pages for this product
import LandingPage from "./views/LandingPage/LandingPage.js";
import LoginPage from "./views/LoginPage/LoginPage.js";
import RegisterPage from "./views/RegisterPage/RegisterPage.js";
import UsersPage from "./views/Users/UsersPage.js";
import UserPage from "./views/User/UserPage.js";
import NavBar from "./views/NavBar/NavBar";
import Footer from "./views/Footer/Footer";
import AuctionsPage from "./views/Money/AuctionsPage";
import CryptoPage from "./views/Money/CryptoPage";
import LottoPage from "./views/Money/LottoPage";
import ForexPage from "./views/Money/ForexPage";
import RealEstatePage from "./views/Money/RealEstatePage";
import SavingsPage from "./views/Money/SavingsPage";
import CdsPage from "./views/Money/CdsPage";
import SecuritiesPage from "./views/Money/SecuritiesPage";
import ConvertPage from "./views/Money/ConvertPage";
import AddPage from "./views/User/AddPage";
import AuctionsPageT from "./views/Teacher/AuctionsPageT"
import ApprovalPage from "./views/Teacher/ApprovalPage"
import AddAdminPage from "./views/Teacher/AddAdminPage"
import ClassesPage from "./views/Teacher/ClassesPage"
import BucksPage from "./views/Teacher/BucksPage"
import TransactionsPage from "./views/Teacher/TransactionsPage"
import EventsPage from "./views/Teacher/EventsPage"
import ClassPage from "./views/Teacher/ClassPage"
import PickerPage from "./views/Teacher/PickerPage"
import SettingsPageT from "./views/Teacher/SettingsPageT"
import SettingsPage from "./views/Admin/SettingsPage"
import ApproveAuctionsPage from "./views/Teacher/ApproveAuctionsPage"
import StudentStorePage from "./views/User/StudentStorePage"
import TeacherStorePage from "./views/Teacher/TeacherStorePage"

//null   Anyone Can go inside
//true   only logged in user can go inside
//false  logged in user can't go inside

const url = window.location.href;

function App() {
  if (url.includes("picker")) {
    return (
      <div style={{ maxHeight: '50px' }}>
        <Route exact path="/teacher/class/picker/:id" component={Auth(PickerPage, true, 1)} />
      </div>
    )
  }

  else {
    return (
      <Suspense fallback={(<div>Loading...</div>)}>
        <NavBar />
        <div style={{ minHeight: 'calc(100vh - 80px)' }}>
          <Switch>
  
            <Route exact path="/teacher/class/picker/:id" component={Auth(PickerPage, true, 1)} />
            
            <Route exact path="/demo">
              <Redirect push to={"../../../../demo/index.html"} />
            </Route>
            <Route exact path="/" component={AuthRedirect(LandingPage, true)} />
            <Route exact path="/register" component={Auth(RegisterPage, false)} />
            <Route exact path="/login" component={Auth(LoginPage, false)} />
  
            <Route exact path="/student/users" component={Auth(UsersPage, true)} />
            <Route exact path="/student/store" component={Auth(StudentStorePage, true)} />
            <Route exact path="/student/users/:id" component={Auth(UserPage, true)} />
            <Route exact path="/student/savings/:id" component={Auth(SavingsPage, true)} />
            <Route exact path="/student/cds/:id" component={Auth(CdsPage, true)} />
            <Route exact path="/student/savings/convert/:id" component={Auth(ConvertPage, true)} />
            <Route exact path="/student/securities/:id" component={Auth(SecuritiesPage, true)} />
            <Route exact path="/student/realEstate/:id" component={Auth(RealEstatePage, true)} />
            <Route exact path="/student/forex/:id" component={Auth(ForexPage, true)} />
            <Route exact path="/student/cryptos/:id" component={Auth(CryptoPage, true)} />
            <Route exact path="/student/raffle/:id" component={Auth(LottoPage, true)} />
            <Route exact path="/student/auctions/:id" component={Auth(AuctionsPage, true)} />
            <Route exact path="/student/addClass/:id" component={Auth(AddPage, true)} />
  
            <Route exact path="/teacher/classes" component={Auth(ClassesPage, true, 1)} />
            <Route exact path="/teacher/store" component={Auth(TeacherStorePage, true, 1)} />
            <Route exact path="/teacher/bucks" component={Auth(BucksPage, true, 1)} />
            <Route exact path="/teacher/transactions" component={Auth(TransactionsPage, true, 1)} />
            <Route exact path="/teacher/events" component={Auth(EventsPage, true, 1)} />
            <Route exact path="/teacher/class/:id" component={Auth(ClassPage, true, 1)} />
            <Route exact path="/teacher/auctions/:id" component={Auth(AuctionsPageT, true)} />
            <Route exact path="/teacher/addAdmin/:id" component={Auth(AddAdminPage, true, 1)} />
            <Route exact path="/teacher/approveAuctions" component={Auth(ApproveAuctionsPage, true, 1)} />
            <Route exact path="/teacher/settings" component={Auth(SettingsPageT, true, 1)} />
  
            <Route exact path="/prin/users/:id" component={Auth(UserPage, true, 2)} />
            <Route exact path="/prin/auctions/:id" component={Auth(AuctionsPageT, true, 2)} />
            <Route exact path="/prin/approved/:id" component={Auth(ApprovalPage, true, 2)} />
            <Route exact path="/prin/settings" component={Auth(SettingsPage, true, 2)} />
          </Switch>
        </div>
        <Footer />
      </Suspense>
    );
  }
  
}

export default App;
