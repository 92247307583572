import axios from 'axios';
import {
    LOGIN_USER,
    REGISTER_USER,
    AUTH_USER,
    LOGOUT_USER,
    GET_USERS,
    GET_USER,
    GET_USER_SPECIAL,
    UPDATE_USER,
    SET_ACCOUNT,
    GET_CLASSES,
    GET_CLASS,
    EDIT_CLASSES,
    UPDATE_CLASS,
    KICK_MEMBER,
    MAKE_AUCTION,
    GET_AUCTIONS,
    REMOVE_ADMIN,
    ADD_ADMIN,
    TOAST,
    GET_BUCK,
    GET_ACCOUNTS,
    GET_BUCK_TRANSACTIONS,
    GET_TEACHERS,
    UPDATE_TCLASS,
    KICK_TEACHER,
    SEARCH_CRYPTO,
    CRYPTO_ACCOUNTS,
    CRYPTO_TRANS,
    CRYPTO_HISTORY,
    BUCKS,
    TEACHER_TRANSACTIONS,
    TEACHER_EVENTS,
    SETTINGS,
    GET_MARKET_ITEMS,
    GET_SCHOOL_TEACHERS,
    TEACHER_BUCK,
    STUDENT_COUNT,
    GET_LOTTO_LATEST,
    GET_LOTTO_PREVIOUS,
    SYMBOL_LOOKUP,
    CD_ACCOUNTS,
    CD_TRANSACTIONS,
    CRYPTOS,
    PURCHASES,
    TAX_BRACKETS
} from './types';
import { USER_SERVER } from '../components/Config.js';

export function executeTax(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/schools/school/tax`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: "nil",
      payload: request
    }
}

export function getTaxBrackets(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/schools/school/tax`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: TAX_BRACKETS,
        payload: request
    }
}

export function getCDPortfolio(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/accounts/CDS`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CD_ACCOUNTS,
        payload: request
    }
}

export function getCDTransactions(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/transactions/CDTransactions`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CD_TRANSACTIONS,
        payload: request
    }
}

export function CDRefund(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/transactions/CDRefund`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: "nil",
        payload: request
    }
}

export function CDTransaction(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/transactions/CDTransaction`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: "nil",
        payload: request
    }
}

export function clearSymbol() {
  return {
    type: SYMBOL_LOOKUP,
    payload: {
      symbol: ""
    }
  }
}

export function symbolLookup(dataToSubmit){
    return {
        type: SYMBOL_LOOKUP,
        payload: dataToSubmit
    }
}

export function purchaseLotto(dataToSubmit){
  const request = axios.put(`${USER_SERVER}/lottery/purchase?quantity=` + dataToSubmit, null)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: 'nil',
    payload: request
  }
}

export function getLottoLatest() {
  const request = axios.get(`${USER_SERVER}/lottery/latest`)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_LOTTO_LATEST,
        payload: request
    }
}

export function getLottoPrevious() {
  const request = axios.get(`${USER_SERVER}/lottery/previous`)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_LOTTO_PREVIOUS,
        payload: request
    }
}

export function getStudentCount(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/schools/school/count`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: STUDENT_COUNT,
        payload: request
    }
}

export function getTeacherBuck(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/bucks/buck`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: TEACHER_BUCK,
        payload: request
    }
}

export function getMarketPurchases(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/marketItems/purchases`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: PURCHASES,
        payload: request
    }
}

export function marketItemBuy(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/marketItems/buy`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: "nil",
        payload: request
    }
}

export function getSchoolTeachers(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/teachers`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_SCHOOL_TEACHERS,
        payload: request
    }
}

export function refundMarketItem(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/marketItems/refund`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: "nil",
        payload: request
    }
}

export function resolveMarketItem(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/marketItems/resolve`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: "nil",
        payload: request
    }
}

export function deleteMarketItem(dataToSubmit) {
  const request = axios.delete(`${USER_SERVER}/marketItems`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: 'nil',
        payload: request
    }
}

export function makeMarketItem(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/marketItems`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: 'nil',
        payload: request
    }
}

export function getMarketItems(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/marketItems`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_MARKET_ITEMS,
        payload: request
    }
}

export function setSettings(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/settings`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: "nil",
        payload: request
    }
}

export function getSettings(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/settings`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: SETTINGS,
        payload: request
    }
}

export function getEvents(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/events`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: TEACHER_EVENTS,
        payload: request
    }
}

export function getTransactions(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/transactions/`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: TEACHER_TRANSACTIONS,
        payload: request
    }
}

export function getBucks(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/bucks`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: BUCKS,
        payload: request
    }
}

export function getCryptos(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/allCrypto`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CRYPTOS,
        payload: request
    }
}

export function cryptoHistory(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/transactions/cryptoTransactions`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CRYPTO_HISTORY,
        payload: request
    }
}

export function cryptoTransaction(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/transactions/cryptoTransaction`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CRYPTO_TRANS,
        payload: request
    }
}

export function approveAuction(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/auctions/approve`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CRYPTO_TRANS,
        payload: request
    }
}

export function rejectAuction(dataToSubmit) {
  const request = axios.delete(`${USER_SERVER}/auctions/reject`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CRYPTO_TRANS,
        payload: request
    }
}

export function getAllAuctions(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/auctions/all`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_AUCTIONS,
        payload: request
    }
}

export function cryptoDetails(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/accounts/allCrypto`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: CRYPTO_ACCOUNTS,
        payload: request
    }
}

export function searchCrypto(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/accounts/crypto`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: SEARCH_CRYPTO,
        payload: request
    }
}

export function getTeachers() {
  const request = axios.get(`${USER_SERVER}/classes/teachers`)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_TEACHERS,
        payload: request
    }
}

export function postClass(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/classes/class`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_CLASSES,
        payload: request
    }
}

export function getClasses() {
  const request = axios.get(`${USER_SERVER}/classes`)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
        type: GET_CLASSES,
        payload: request
    }
}

export function getUser(dataToSubmit){
  const request = axios.get(`${USER_SERVER}/users/user`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)
     
  return {
    type: GET_USER,
    payload: request
  }
}

export function getUserSpecial(dataToSubmit){
  const request = axios.get(`${USER_SERVER}/users/user`, { params: dataToSubmit })
    .then(response => response.data)
    .catch(err => err.response.data)
     
  return {
    type: GET_USER_SPECIAL,
    payload: request
  }
}

export function getUsers(dataToSubmit){
  const request = axios.get(`${USER_SERVER}/users`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)
     
  return {
    type: GET_USERS,
    payload: request
  }
}

export function registerUser(dataToSubmit){
  const request = axios.post(`${USER_SERVER}/users/register`,dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)
  
  return {
    type: REGISTER_USER,
    payload: request
  }
}

export function updateUser(dataToSubmit){
  const request = axios.put(`${USER_SERVER}/users/user`,dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)
  
  return {
    type: UPDATE_USER,
    payload: request
  }
}

export function localUpdateUser(dataToSubmit){
  return {
    type: UPDATE_USER,
    payload: dataToSubmit
  }
}

export function loginUser(dataToSubmit){
  const request = axios.post(`/auth/al/login`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: LOGIN_USER,
    payload: request
  }
}

export function auth(){
  const request = axios.get(`${USER_SERVER}/users/auth`)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: AUTH_USER,
    payload: request
  }
}

export function logoutUser(){
  const request = axios.get(`/auth/al/logout`)
    .then(response => response.data)
    .catch(err => err.response.data)
  return {
    type: LOGOUT_USER,
    payload: request
  }
}

export function setAccount(account){
  return {
    type: SET_ACCOUNT,
    payload: account
  }
}

export function saveEdit(dataToSubmit){
  const request = axios.put(`${USER_SERVER}/classes/class`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: EDIT_CLASSES,
    payload: request
  }
}

export function removeClass(dataToSubmit) {
  const request = axios.delete(`${USER_SERVER}/classes/class`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: "nil",
    payload: request
  }
}

export function regenCode(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/class/addCode`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: UPDATE_CLASS,
    payload: request
  }
}

export function regenAdminCode(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/class/addCode`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: UPDATE_TCLASS,
    payload: request
  }
}

export function selectClass(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/classes/class`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_CLASS,
    payload: request
  }
}

export function kickUser(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/class/kick`, dataToSubmit)
    .then(response => dataToSubmit)
    .catch(err => err.response.data)

  return {
    type: KICK_MEMBER,
    payload: request
  }
}

export function kickTeacher(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/class/kick`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: KICK_TEACHER,
    payload: request
  }
}

export function makeAuction(dataToSubmit){
  const request = axios.post(`${USER_SERVER}/auctions`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: MAKE_AUCTION,
    payload: request
  }
}

export function removeAuction(dataToSubmit){
  const request = axios.delete(`${USER_SERVER}/auctions/auction`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: MAKE_AUCTION,
    payload: request
  }
}

export function getAuctions(dataToSubmit){
  const request = axios.get(`${USER_SERVER}/auctions`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_AUCTIONS,
    payload: request
  }
}

export function getStudentAuctions(dataToSubmit){
  const request = axios.get(`${USER_SERVER}/auctions/student`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_AUCTIONS,
    payload: request
  }
}

export function placeBid(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/auctions/placeBid`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)


  return {
    type: "nil",
    payload: request
  }
}

export function getClassMembers(dataToSubmit){
  const request = axios.get(`${USER_SERVER}/classes/member`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_CLASSES,
    payload: request
  }
}

export function removeAdmin(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/removeAdmin`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: REMOVE_ADMIN,
    payload: request
  }
}

export function addAdmin(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/addAdmin`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: ADD_ADMIN,
    payload: request
  }
}

export function addClass(dataToSubmit) {
  const request = axios.put(`${USER_SERVER}/classes/addClass`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)
  return {
    type: GET_CLASSES,
    payload: request
  }
}

export function postTransaction(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/transactions/payTransaction`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: TOAST,
      payload: request
    }
}

export function postTransactions(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/transactions/payTransactions`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: TOAST,
      payload: request
    }
}

export function postConversion(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/transactions/conversionTransaction`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: TOAST,
      payload: request
    }
}

export function getStudentUBucks(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/accounts/account/student`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_BUCK,
    payload: request
  }
}

export function getStudentAccounts(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/accounts/all`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_ACCOUNTS,
    payload: request
  }
}

export function getBuckTransactions(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/transactions/buckTransactions`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: GET_BUCK_TRANSACTIONS,
    payload: request
  }
}

export function getExchangeRate(dataToSubmit) {
  const request = axios.get(`${USER_SERVER}/accounts/exchangeRate`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

  return {
    type: SET_ACCOUNT,
    payload: request
  }
}

export function resetPassword(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/users/resetPassword`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: TOAST,
      payload: request
    }
}

export function resetStaffPassword(dataToSubmit) {
  const request = axios.post(`${USER_SERVER}/users/resetStaffPassword`, dataToSubmit)
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: "nil",
      payload: request
    }
}

export function deleteUser(dataToSubmit) {
  const request = axios.delete(`${USER_SERVER}/users/user`, {params: dataToSubmit})
    .then(response => response.data)
    .catch(err => err.response.data)

    return {
      type: TOAST,
      payload: request
    }
}